
// Site name
export const copyrighted_name = "Website";

// Default page title
export const website_name = "Website";

// Google Analytics User ID (UA-XXXXXXXX-X)
export const ga_id = "";

export const site_lang = {
    en: {
        label: "English",
        value: "en",
    },
    zh: {
        label: "中文",
        value: "zh",
    },
    bm: {
        label: "Malay",
        value: "bm",
    }
};

export const options = {
    status: {
        0: 'Inactive',
        1: 'Active'
    },
};

export const phone = [
    {
        "code": "my",
        "label": "+60",
        "value": "+60",
        "image": "/assets/images/flag/my.png"
    },
    {
        "code": "th",
        "label": "+66",
        "value": "+66",
        "image": "/assets/images/flag/th.png"
    },
    {
        "code": "id",
        "label": "+62",
        "value": "+62",
        "image": "/assets/images/flag/id.png"
    }
];

/**
 * @constant {Object}
 * @global
 */
export const pagelink = {
    home: {
        homepage: '/'
    },
    authentication: {
        login: '/login',
        signup: '/signup',
        verification: '/verification',
        registerSuccessful: '/registration-successful',
        forgetPassword: '/forget-password'
    },
    review: {
        homepage: '/review',
        reviewhow: '/review/how-we-review',
        singleReview: '/review/company/',
        blacklisted: '/review/blacklisted',
        complaint: '/review/complaint',
        submitComplaint: '/review/complaint/submit-complaint',
        vote: '/review/vote-ranking',
        grade: '/review/grade-ranking'
    },
    user: {
        profile: '/user/profile',
        emailVerification: '/user/email-verification/',
        checkOut: '/user/checkout/',
        paymentSuccessful: '/user/checkout/payment-successful',
        subscriptionCheckOut: '/user/subscription/payment'
    },
    guide: {
        homepage: '/guide',
        sports: '/guide/sports',
        casino: '/guide/casino',
        slots: '/guide/slots',
        news: '/guide/news'
    },
    article: {
        articleMidUrl: '/article/'
    },
    bonus: {
        homepage: '/bonus',
    },
    general: {
        aboutUs: '/about-us',
        contactUs: '/contact-us',
        monthlySponsored: '/monthly-sponsored',
        privacyPolicy: '/privacy-policy',
        responsibleGaming: '/responsible-gaming',
        termsAndCondition: '/terms-and-conditions',
        certificate: '/certificate/',
        pageNotFound: '/404',
        pageServerError: '/500',
        pageUnauthorisedError: '/401'
    }
}

const Site = () => { };
export default Site;