import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import store, { persistor } from "@/store";
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import Script from "next/script"
import Layout from "@/components/layout";
import Maintenance from "@/components/maintenance";
import { MantineProvider, createEmotionCache } from "@mantine/core";
// import * as Sentry from "@sentry/react";
import "../../public/assets/css/bootstrap.min.css"
import "../../public/assets/css/style.css"
import "../../public/assets/css/responsive.css"
import "../../public/assets/css/odometer.css";
import { useTranslation } from 'next-i18next';
import { getCookie } from "cookies-next";
// import { useRouter } from "next/router";
import _ from 'lodash';
import { _getURL } from "./api/index";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useRouter } from "next/router";

/*
if (process.env.NEXT_PUBLIC_ENV !== 'dev' && !_.isEmpty(process.env.NEXT_PUBLIC_SENTRY_DSN)) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0, //lower the value in production
  });
}
*/

function MyApp({ Component, pageProps: { ...pageProps },seo }) {
  // const router = useRouter();
  const { t } = useTranslation('common');
  const router = useRouter();

  let userCookies = getCookie("user") && JSON.parse(getCookie("user"));
  userCookies = userCookies ?? undefined;

  const getLayout = Component.getLayout || null;
  const newProps = { ...pageProps, session: { user: userCookies }, t };

  const mantineStyleCache = createEmotionCache({ key: 'mantine' });

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-TNWT62W'
    });
  }, []);

  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {() => (
        <>
          <Head>
            {/* favicon start */}
            <link rel="icon" type="image/png" sizes="16x16" href="/assets/images/icon/Icon-16.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/assets/images/icon/Icon-32.png" />
            <link rel="icon" type="image/png" sizes="48x48" href="/assets/images/icon/Icon-48.png" />
            <link rel="icon" type="image/png" sizes="64x64" href="/assets/images/icon/Icon-64.png" />
            <link rel="icon" type="image/png" sizes="128x128" href="/assets/images/icon/Icon-128.png" />
            <link rel="shortcut icon" href="/assets/images/icon/Icon-32.png" />
            {/* favicon end */}
            {/* fontawesome start */}
            <link href="/assets/plugins/fontawesome/css/fontawesome.css" rel="stylesheet" />
            <link href="/assets/plugins/fontawesome/css/brands.css" rel="stylesheet" />
            <link href="/assets/plugins/fontawesome/css/solid.css" rel="stylesheet" />
            <link href="/assets/plugins/fontawesome/css/regular.css" rel="stylesheet" />
            {/* fontawesome end */}
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            <title>Ugrado</title>
            <meta name='title' content='Ugrado Transparent iGaming Service Test Labs Rating & Awards'></meta>
            <meta name='description' content="Leading transparent for iGaming player ratings and awards of great quality brands. Guild & instant experiences to help players make better choices."></meta>
            <meta name="keywords" content=""></meta>
            <meta itemProp='title' content='Ugrado Transparent iGaming Service Test Labs Rating & Awards'></meta>
            <meta itemProp='description' content="Leading transparent for iGaming player ratings and awards of great quality brands. Guild & instant experiences to help players make better choices."></meta>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={typeof window !== 'undefined' ? `${window.location.origin}${router.asPath}` : undefined} />
            <link rel="canonical" href={typeof window !== 'undefined' ? `${window.location.origin}${router.asPath}` : undefined} />

            <meta name="google-site-verification" content="KujGtncPLQ2eVElArK8t5OBhLdX_aQD6mIsmiWKFCYE" />
            <meta name="yandex-verification" content="2db16a4f17ae1e76" />
            <meta name="p:domain_verify" content="00932558840654e0b22e9a749348151b" />

            <link rel="preload" href="/assets/font/CenturyGothic.eot" as="font" crossOrigin="anonymous" />
            <link rel="preload" href="/assets/font/CenturyGothic.ttf" as="font" crossOrigin="anonymous" />
            
            <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-63f594e5ba712205"></script>
            <style dangerouslySetInnerHTML={{__html: `.atm { display: none !important; }`}}></style>
          </Head>
          <MantineProvider emotionCache={mantineStyleCache} withGlobalStyles theme={ { fontFamily: 'Century Gothic' } }>
            {
              process.env.NEXT_PUBLIC_MAINTENANCE_MODE == 1 ?
                <Maintenance />
              : getLayout ?
                getLayout(<Component {...newProps} />)
              : <Layout>
                  <Component {...newProps} />
                </Layout>
            }
            <Script src="/assets/js/bootstrap.bundle.min.js" strategy="beforeInteractive"></Script>
            <Script strategy="afterInteractive" dangerouslySetInnerHTML={{__html: `var tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            var tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))`}}></Script>
          </MantineProvider>
        </>
      )}
    </PersistGate>
  </Provider>
}

export default appWithTranslation(MyApp)