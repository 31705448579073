import LinkNext from "next/link";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/react";

export function Link({ href = "/", children, ...props }) {
    const route = useRouter();
    return <LinkNext href={href} locale={false} {...props}>{children}</LinkNext>
}

export function log_message(msg) {
    let message = typeof msg === 'object' ? JSON.stringify(msg) : msg;
    Sentry.captureMessage(message);
}

export function errMsgHandling(err = {},default_msg = 'Error Occur'){
    let errJson = { message: default_msg };

    if(err.response && err.response.data && err.response.data.message){
        errJson.message = err.response.data.message;
    }
    else if(err.data && err.data.message){
        errJson.message = err.data.message;
    }
    else if(err.message){
        errJson.message = err.message;
    }
    else if(err && typeof err === 'string'){
        errJson.message = err;
    }

    return errJson;
}