const Maintenance = () => {

  return (
    <>
      <div style={{textAlign:"center",padding:"50px"}}>
          <img src="/assets/images/maintenance.png" style={{width: "400px"}}/>
          <h1 style={{fontSize:"50px"}}>We&rsquo;ll be back soon!</h1>
          <div style={{display: "block" , textAlign: "center", width: "100%", margin: "0 auto"}}>
              <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.</p>
              <p>&mdash; The Team</p>
          </div>
      </div>
    </>
  );
};

export default Maintenance;
