import { Link } from "@/helpers/common_helper";
import { pagelink } from "@/config/site";
import { useEffect, useState } from "react";
import _ from 'lodash';
import { getCookie, setCookie, hasCookie } from 'cookies-next';
import Modal from 'react-modal';
import Script from "next/script";
import { useRouter } from "next/router";

export default function Footer(props) {
    const { t, session } = props;
    const [hasMember, setHasMember] = useState(false);
    const [hasDiamond, setHasDiamond] = useState(false);
    const [diamond, setDiamond] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const [ userData, setUserData ] = useState({});
    const router = useRouter()

    // set acknowledgement
    const _acknowledgeConfirmed = () => {
        setCookie('isAcknowledgedConfirm', true, { maxAge: "21600" });
        setModalIsOpen(false);
        window.location = location.pathname;  //reload current page
    }

    const _closeModal = () => {
        setModalIsOpen(false);
    }

    useEffect(()=>{
        // set user details
        let memberData = (session && session.user != null) ? session.user.member : '';

        if (!_.isEmpty(memberData.member_id)) {
            setHasMember(!_.isEmpty(memberData.member_id) ? true: false);
            setHasDiamond(!_.isEmpty(memberData.available_diamond) ? true: false);
            setDiamond(memberData.available_diamond ? Number(memberData.available_diamond).toFixed() : 0);
            setUserData(memberData)
        }

        // check acknowledge
        const acknowledgedConfirm = hasCookie('isAcknowledgedConfirm') ? getCookie('isAcknowledgedConfirm') : '';
        if(acknowledgedConfirm){
            setModalIsOpen(false);
        }
    },[]);
    
    // Check for scrollbar when modal is opened or closed
    useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = modalIsOpen ? 'hidden' : 'auto';
    }, [modalIsOpen]);

    return <>
        {/* < !--footer start here-- > */}
        {/* <footer className="d-none d-xl-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9">
                        <div className="row">

                            <div className="col-md-3">
                                <h5 className="menu__tittle">{t('Site_name')}</h5>
                                <ul className="menu__tittle_list">
                                    <li><Link href="#">{t('Review')}</Link></li>
                                    <li><Link href="#">{t('Blacklisted_casinos')}</Link></li>
                                    <li><Link href={pagelink.general.monthlySponsored}>{t('Monthly_sponsored')}</Link></li>
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <h5 className="menu__tittle">{t('Opportunity')}</h5>
                                <ul className="menu__tittle_list">
                                    <li><Link href="#">{t('Partnership')}</Link></li>
                                    <li><Link href="#">{t('Sponsorship')}</Link></li>
                                    <li><Link href="#">{t('Appraisal Report')}</Link></li>
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <h5 className="menu__tittle">{t('Guide')}</h5>
                                <ul className="menu__tittle_list">
                                    <li><Link href={pagelink.guide.sports}>{t('Sports')}</Link></li>
                                    <li><Link href={pagelink.guide.casino}>{t('Casino')}</Link></li>
                                    <li><Link href={pagelink.guide.slots}>{t('Slots')}</Link></li>
                                </ul>
                            </div>

                            <div className="col-md-3">
                                <h5 className="menu__tittle">{t('Others')}</h5>
                                <ul className="menu__tittle_list">
                                    <li><Link href={pagelink.general.termsAndCondition}>{t('Terms_conditions')}</Link></li>
                                    <li><Link href={pagelink.general.privacyPolicy}>{t('Privacy_policy')}</Link></li>
                                    <li><Link href="#">{t('About_us')}</Link></li>
                                    <li><Link href={pagelink.general.contactUs}>{t('Contact_us')}</Link></li>
                                    <li><Link href="#">{t('Newsletter')}</Link></li>
                                    <li><Link href={pagelink.general.responsibleGaming}>{t('Responsible_gaming')}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <h5 className="menu__tittle">{t('Country_text')}</h5>
                        <div className="menu__select">
                            <select className="form-select shadow-none border-0 rounded-0 p-0" aria-label="Default select example">
                                <option defaultValue>{t('Country')}</option>
                            </select>
                        </div>
                        <h5 className="menu__tittle">{t('Language_text')}</h5>
                        <div className="menu__select">
                            <select className="form-select shadow-none border-0 rounded-0 p-0 mb-0" aria-label="Default select example">
                                <option defaultValue>{t('Language')}</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        </footer>
        <div className="mobile-footer d-flex d-xl-none">
            <ul>
                <li>
                    {
                        hasMember ?
                        <Link href={pagelink.user.profile}>
                            <a><img src="/assets/images/footer/user-icon.png" alt="" className="img-fluid" /><span>{t('Profile')}</span></a>
                        </Link>
                        : <Link href={pagelink.authentication.login}>
                            <a><img src="/assets/images/footer/user-icon.png" alt="" className="img-fluid" /><span>{t('Profile')}</span></a>
                        </Link>
                    }
                </li>
                <li>
                    <Link href={pagelink.guide.homepage}>
                        <a>
                            <img src="/assets/images/footer/guide-icon.png" alt="" className="img-fluid" />
                            <span>{t('Guide')}</span>
                        </a>
                    </Link>
                </li>
                <li className="footer-review">
                    <a href="#"><img src="/assets/images/footer/Shield-icon.png" alt="" className="img-fluid" />
                        <span>{t('Review')}</span>
                    </a>
                </li>
                <li>
                    <a href={pagelink.general.aboutUs}>
                        <img src="/assets/images/footer/info-icon.png" alt="" className="img-fluid" />
                        <span>{t('About_us')}</span>
                    </a>
                </li>
                <li>
                    <a href={pagelink.general.contactUs}>
                        <img src="/assets/images/footer/mail-icon.png" alt="" className="img-fluid" />
                        <span>{t('Contact_us')}</span>
                    </a>
                </li>
            </ul>
        </div> */}
        
        <footer className="d-none d-xl-block">
            <div className="footer-content container d-flex justify-content-evenly align-items-center">
                <Link href={pagelink.general.termsAndCondition}>{t('Terms_conditions')}</Link>
                <Link href={pagelink.general.privacyPolicy}>{t('Privacy_policy')}</Link>
                <Link href={pagelink.general.responsibleGaming}>{t('Responsible_gaming')}</Link>
                <Link href={pagelink.general.contactUs}>{t('Contact_us')}</Link>
                <Link href={pagelink.general.aboutUs}>{t('About_us')}</Link>
                <Link href={pagelink.general.monthlySponsored}>Partnership</Link>
                {/* <a href="" target="_blank">Partnership</a> */}
                {/* <a className="disabled" data-bs-toggle="tooltip" data-bs-title="Coming Soon" style={ { "cursor": "default" } }>Partnership</a>  */}
            </div>
            <div className="d-none d-xl-flex justify-content-center align-items-center" style={ { backgroundColor: "#bb9b6a", "padding": "5px 0" } }>
                Copyright &#169; 2022 Ugrado.com. All Rights Reserved.
            </div>
        </footer>
        <div className="mobile-footer d-flex d-xl-none">
            <ul>
                <li>
                    <a href="/">
                        <img src="/assets/images/footer/home-icon.png" alt="" className="img-fluid" />
                        <span>Home</span>
                    </a>
                </li>
                <li>
                    <a href="/bonus">
                        <img src="/assets/images/footer/bonus-icon.png" alt="" className="img-fluid" />
                        <span>Bonus</span>
                    </a>
                </li>
                <li className="footer-review">
                    <a href="/review/grade-ranking"><img src="/assets/images/footer/vector-icon.png" alt="" className="img-fluid" />
                        <span>Review</span>
                    </a>
                </li>
                <li>
                    <a href="/guide">
                        <img src="/assets/images/footer/guide-icon.png" alt="" className="img-fluid" />
                        <span>Guide</span>
                    </a>
                </li>
                <li>
                    {
                        hasMember ?
                        <a href="/user/profile">
                            <img src={ userData.profile_image ? `${process.env.NEXT_PUBLIC_FS_URL}${userData.profile_image}` : '/assets/images/profile/profile-user-icon.png' } className="img-fluid" style={{ borderRadius: '50%', border: '2px solid #bb9b6a' }} />
                            <span>Profile</span>
                        </a> :
                        <a href="/login">
                            <img src="/assets/images/footer/user-icon.png" alt="" className="img-fluid" />
                            <span>Login</span>
                        </a>
                    }
                </li>
            </ul>
        </div>

        {/** Modal part */}
        <Modal
            isOpen={modalIsOpen}
            ariaHideApp={false}
            portalClassName={"contact-modal"}
            className={"contact-modalOpened modal-lg"}
            overlayClassName={"contact-modalOverlayOpened"}
            style={{
                content: {
                    maxWidth: '520px',
                    margin: "250px auto",
                    outline: "none"
                }
            }}
            aria={{ labelledby: "exampleModalLabel" }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content modal-acknowledge">
                    <div className="modal-body">
                        <img src="/assets/images/logo.png" alt="" className="img-fluid mx-auto row col pb-4" />
                        <div className="modal-acknowledge-size">
                            <div className="modal-acknowledge-big-title text-center">
                                <b>This website is strictly for non-muslim aged 21 years old and above only.</b>
                            </div>
                            <div className="modal-acknowledge-small-title text-center py-3">Are you sure to proceed?</div>
                            <div className="modal-acknowledge-yes-no row">
                                <button type="button" className="btn btn-default btn-filled gradient-hover-effect col mx-3" onClick={() => { _acknowledgeConfirmed() }}>
                                    {t('Yes')}
                                </button>
                                <button type="button" className="btn btn-default btn-outline col mx-3" onClick={() => router.reload()}>
                                    {t('No')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </Modal>
    </>
}
