import * as types from "./types"

var expired = new Date();
expired.setHours(expired.getHours() + 6);

const initialState = {
    user: null,
    expired: expired,
    path: {
    },
    loading: false
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case types.RESET:
            return initialState
        case types.UPDATE_USER:
            return { ...state, user: action.user }
        case types.UPDATE_PATH:
            return { ...state, path: { ...state.path, [action.key]: action.path } }
        case types.RESET_PATH:
            return { ...state, path: {} }
        case types.RESET_USER:
            return { ...state, user: null }
        case types.TOGGLE_LOADING:
            return { ...state, loading: action.status }
        default:
            return {
                ...state, path: {
                },
                loading: false
            }
    }
}