import React from "react";
import { useEffect, useState } from "react";
import { hasCookie, deleteCookie } from 'cookies-next';
import { Link } from "@/helpers/common_helper";
import { pagelink } from "../../config/site";
import _ from 'lodash';
import { Button, UnstyledButton } from "@mantine/core";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

export default function Index(props) {
    const { t: translate } = useTranslation();
    var { session, openSearchDropdown } = props;
    const [hasMember, setHasMember] = useState(false);
    const [hasDiamond, setHasDiamond] = useState(false);
    const [diamond, setDiamond] = useState(0);
    const [ userData, setUserData ] = useState({});
    const [certificatePageCurrent, setCertificatePageCurrent] = useState(false);
    const [complaintAllow, setComplaintAllow] = useState(true);

    const router = useRouter();

    useEffect(()=>{
        // set user details
        let memberData = (session && session.user != null) ? session.user.member : '';

        if (!_.isEmpty(memberData.member_id)) {
            setHasMember(!_.isEmpty(memberData.member_id) ? true: false);
            setHasDiamond(!_.isEmpty(memberData.available_diamond) ? true: false);
            setDiamond(memberData.available_diamond ? Number(memberData.available_diamond).toFixed() : 0);
            setUserData(memberData)
        }

        if(router.pathname == pagelink.general.certificate + '[id]'){
            setCertificatePageCurrent(true);
        }
    },[]);

    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50 

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
        // add your conditional logic here
        if (isLeftSwipe) bootstrap.Offcanvas.getOrCreateInstance(document.querySelectorAll('#offcanvasExample')).toggle()
    }

    return <>
        <header>
            <div className="header-left-empty-content d-none d-xl-block"></div>
            <div className="container d-flex justify-content-center align-items-center col-12 col-xl-7">
                <div className="navigation">
                    {certificatePageCurrent ? 
                    <nav className="navbar navbar-expand-xl">
                        {/* Certificate page */}
                        <div className="d-none d-xl-flex" style={{ width: '100%', justifyContent: 'space-between' }}>
                            <a className="navbar-brand d-none d-xl-block mx-0" href={pagelink.home.homepage}>
                                <img src="/assets/images/logo.png" alt="" className="img-fluid" />
                            </a>
                            <div className="" style={{ display: 'flex' }}>
                                <a className="nav-link" href={pagelink.home.homepage} style={{ color: 'black' }}>{translate('Home')}</a>
                                <a className="create-certificate-button" href={pagelink.general.contactUs}>
                                    {translate('Create_my_own_certificate')}
                                </a>
                            </div>
                        </div>
                        <div className="d-flex d-xl-none" style={{ width: '100%', justifyContent: 'center' }}>
                            <a className="navbar-brand mx-0" href={pagelink.home.homepage}>
                                <img src="/assets/images/logo.png" alt="" className="img-fluid" />
                            </a>
                        </div>
                    </nav> : 
                    <nav className="navbar navbar-expand-xl">
                        {/* Normal page */}
                        <div className="navbar-mobile align-items-center d-flex d-xl-none">
                            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" aria-expanded="false" aria-label="Toggle navigation">
                                <img src="/assets/images/menu-icon.png" alt="" className="img-fluid" />
                            </button>
                            <div className="navbar-brand">
                                <a href="/">
                                    <img src="/assets/images/logo-mobile.png" alt="" className="img-fluid" />
                                </a>
                            </div>
                            <a className="img-fluid navbar-right-menu-icon search-icon" >{/* <img src="/assets/images/search-icon.png" alt=""  /> */}</a>
                        </div>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav navbar-nav-left">
                                <li className="nav-item">
                                    <Link href={pagelink.home.homepage}>
                                        <a className="nav-link">{translate('Home')}</a>
                                    </Link>
                                    {/* <a className="nav-link active" href="index.html">Home</a> */}
                                </li>
                                {/* <li className="nav-item">
                                    <Link href='/review'>
                                        <a className="nav-link">{translate('Review')}</a>
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link href={pagelink.review.homepage}>
                                        <a className="nav-link">{translate('Review')}</a>
                                    </Link>
                                    <div className="submenu">
                                        <ul>
                                            <li><a className="overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap' }} href={pagelink.review.grade}>{translate('Ranking')}</a></li>
                                            <li><a className="overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap' }} href={pagelink.review.vote}>{translate('Vote')}</a></li>
                                            {complaintAllow ? 
                                            <li><a className="overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap' }} href={pagelink.review.complaint}>{translate('Complaint')}</a></li> :
                                            <li tabIndex="0" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Coming Soon">
                                                <a className="overflow-hidden disabled text-decoration-none text-black-50" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', cursor: 'default' }}>{translate('Complaint')}</a>
                                            </li>}
                                            <li><a className="overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap' }} href={pagelink.review.reviewhow}>{translate('How We Review')}</a></li>
                                        </ul>
                                    </div>
                                </li>
                                {/* <li className="nav-item" tabIndex="0" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Coming Soon">
                                    <a className="nav-link disabled">{t('Review')}</a> 
                                    <a className="nav-link" href="review.html">Review</a>
                                </li> */}
                                
                            </ul>
                            <a className="navbar-brand d-none d-xl-block mx-5" href={pagelink.home.homepage}>
                                <img src="/assets/images/logo.png" alt="" className="img-fluid" />
                            </a>
                            <ul className="navbar-nav navbar-nav-right">
                                <li className="nav-item">
                                {/* <li className="nav-item" tabIndex="0" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Coming Soon">
                                    <a className="nav-link disabled">{t('Guide')}</a>  */}
                                    <Link href={pagelink.guide.homepage}>
                                        <a className="nav-link">{translate('Guide')}</a>
                                    </Link>
                                    <div className="submenu">
                                        <ul>
                                            <li><Link href={pagelink.guide.sports}>{translate('Sports')}</Link></li>
                                            <li><Link href={pagelink.guide.casino}>{translate('Casino')}</Link></li>
                                            <li><Link href={pagelink.guide.slots}>{translate('Slots')}</Link></li>
                                            <li><Link href={pagelink.guide.news}>{translate('News')}</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link href={pagelink.bonus.homepage}>
                                        <a className="nav-link">{translate('Bonus')}</a>
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="contact-us.html">Contact us</a>
                                </li> */}
                            </ul>
                        </div>
                    </nav> }
                </div>
            </div>
            <div className="navbar-right-menu d-none d-xl-flex">
                {/* <ul>
                    {
                        hasDiamond &&
                        <li className="d-none d-xl-block">
                            <span className="diamond-user"><img src="/assets/images/diamond-icon.png" alt="" />{diamond}</span>
                        </li>
                    }
                    <li className="d-none d-xl-block m-0">
                        {
                            hasMember ? 
                            <Link href={pagelink.user.profile}>
                                <a style={{cursor:'pointer'}}><img src="/assets/images/user-icon.png" alt="" className="img-fluid" /></a>
                            </Link> 
                            : <Link href={pagelink.authentication.login}>
                                <a style={{cursor:'pointer'}}><img src="/assets/images/user-icon.png" alt="" className="img-fluid" /></a>
                            </Link>
                        }
                    </li>
                    <li>
                        <a className="search-icon" style={{ cursor: 'pointer' }} onClick={()=>{ openSearchDropdown() }}>
                            <img src="/assets/images/search-icon.png" alt="" className="img-fluid" />
                        </a>
                    </li>
                </ul> */}
                {
                    certificatePageCurrent ? 
                    <></> : 
                    hasMember ? <>
                        <a href={pagelink.user.profile} style={{ color: 'black' }}>
                            <div className="diamond-login">
                                <div className="diamond-user">
                                    <span className="diamond-icon"><img src="/assets/images/diamond-user.png" alt=""  className="img-fluid"/></span>
                                    <div className="diamond-content">
                                        <span className="diamond-amount mx-auto"><b>{diamond}</b></span>
                                        <span className="diamond-plus mx-auto me-1"><b>+</b></span>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div className="col-7 row align-items-center text-end">
                            {/* <ul className="col text-center navbar-right-menu-icon navbar-nav navbar-nav-right">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img className="img-fluid" style={{ height: 27 }} src="/assets/images/user-icon.png" alt="" />
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#">Action</a></li>
                                        <li><a class="dropdown-item" href="#">Another action</a></li>
                                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href={pagelink.user.profile}>
                                        <img className="img-fluid" style={{ height: 27 }} src="/assets/images/user-icon.png" alt="" />
                                    </a>
                                    <div className="submenu">
                                        <ul>
                                            <li><a href={pagelink.user.profile}>View Profile</a></li>
                                            <li><Link href={pagelink.guide.sports}>{t('Sports')}</Link></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul> */}
                            
                            <div className="navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav navbar-nav-left">
                                    <li className="nav-item">
                                        <Link href={pagelink.user.profile}>
                                            <a className="col text-center navbar-right-menu-icon nav-item" href={pagelink.user.profile}><img src={ userData.profile_image ? `${process.env.NEXT_PUBLIC_FS_URL}${userData.profile_image}` : '/assets/images/profile/profile-user-icon.png' } className="img-fluid" style={{ borderRadius: '50%', border: '2px solid #bb9b6a', height: 50 }} /></a>
                                        </Link>
                                        <div className="submenu" style={{ top: 50 }}>
                                            <ul>
                                                <li><a className="px-0 text-center" style={{ cursor: 'pointer' }} href={pagelink.user.profile}>{translate('Profile')}</a></li>
                                                <li><a className="px-0 text-center" style={{ cursor: 'pointer' }} onClick={() => { deleteCookie('user'); router.reload(); return false; }}>{translate('Log Out')}</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            {/* <a className="col text-center navbar-right-menu-icon" href={pagelink.user.profile}><img className="img-fluid" style={{ height: 27 }} src="/assets/images/user-icon.png" alt="" /></a> */}
                            {/* <a className="col text-center navbar-right-menu-icon search-icon" href="#"><img className="img-fluid" style={{ height: 27 }} src="/assets/images/search-icon.png" alt=""  /></a> */}
                            {/* <Button className="col-6" style={{ backgroundColor: '#bb9b6a' }} onClick={() => { deleteCookie('user'); router.reload() }}>Log Out</Button> */}
                        </div>
                    </> : 
                    <Button className="col-6" style={{ backgroundColor: '#bb9b6a' }} onClick={() => { router.push('/login') }}>{translate('Login')}</Button>
                }
            </div>
        </header>
        
        <style dangerouslySetInnerHTML={{ __html: `
            .list-group-item button.accordion-button:not(.collapsed) {
                color: unset;
                background-color: unset;
                box-shadow: unset;
            }
            .list-group-item button.accordion-button:focus {
                z-index: unset;
                border-color: unset;
                outline: unset;
                box-shadow: unset;
            }
        ` }} />

        <div className="offcanvas offcanvas-start w-75" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">
                <button type="button" className="btn-close pt-5" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul className="list-group list-group-flush">
                    {
                        hasMember ?
                        <li className="list-group-item px-0 pb-5">
                            <div className="d-flex align-items-center" onClick={() => { router.push('/user/profile') }}>
                                <div className="float-start d-flex justify-content-center align-items-center" style={{ width: 75, height: 75 }}>
                                    <img src={ userData.profile_image ? `${process.env.NEXT_PUBLIC_FS_URL}${userData.profile_image}` : '/assets/images/profile/profile-user-icon.png' } className="img-fluid" style={{ borderRadius: '50%', border: '2px solid #bb9b6a', height: 50 }} />
                                </div>
                                <div className="float-start px-4">{ userData.full_name }</div>
                                {/* <div className="float-end px-4">{ userData.full_name }</div> */}
                            </div>
                        </li> :
                        <li className="list-group-item px-0 pb-4 d-flex justify-content-around">
                            <Button data-bs-dismiss="offcanvas" variant="subtle" color="dark" className="fw-light" leftIcon={<img className="img-fluid" style={{ height: 27 }} src='/assets/images/sidebar/login.png' />} onClick={() => { router.push('/login') }}>{translate('Login')}</Button>
                            <Button data-bs-dismiss="offcanvas" variant="subtle" color="dark" className="fw-light" leftIcon={<img className="img-fluid" style={{ height: 27 }} src='/assets/images/sidebar/register.png' />} onClick={() => { router.push('/signup') }}>{translate('Register')}</Button>
                        </li>
                    }
                    <Link href={pagelink.home.homepage}><li data-bs-dismiss="offcanvas" className="list-group-item px-0">{ translate('Home') }</li></Link>
                    <Link href={pagelink.general.aboutUs}><li data-bs-dismiss="offcanvas" className="list-group-item px-0">{ translate('About_us') }</li></Link>
                    <li className="list-group-item px-0 accordion-flush" id="reviewAccordion">
                        <button className="accordion-button p-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#reviewCollapse" aria-expanded="false" aria-controls="reviewCollapse" style={{ fontSize: 16 }}>
                            { translate('Review') }
                        </button>
                        <div id="reviewCollapse" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#reviewAccordion">
                            <ul className="accordion-body p-0 ps-2">
                                <Link href={pagelink.review.grade}><li data-bs-dismiss="offcanvas" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}>{ translate('Ranking') }</li></Link>
                                <Link href={pagelink.review.vote}><li data-bs-dismiss="offcanvas" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}>{ translate('Vote') }</li></Link>
                                <Link href={pagelink.review.complaint}><li data-bs-dismiss="offcanvas" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}>{ translate('Complaint') }</li></Link>
                                <Link href={pagelink.review.reviewhow}><li data-bs-dismiss="offcanvas" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}>{ translate('How We Review') }</li></Link>
                                {/* <li data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Coming Soon" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}><a className="disabled text-decoration-none text-black-50">{ translate('Complaint') }</a></li> */}
                            </ul>
                        </div>
                    </li>
                    <li className="list-group-item px-0 accordion-flush" id="guideAccordion">
                        <button className="accordion-button p-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#guideCollapse" aria-expanded="false" aria-controls="guideCollapse" style={{ fontSize: 16 }}>
                            { translate('Guide') }
                        </button>
                        <div id="guideCollapse" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#guideAccordion">
                            <ul className="accordion-body p-0 ps-2">
                                <Link href={pagelink.guide.sports}><li data-bs-dismiss="offcanvas" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}>{ translate('Sports') }</li></Link>
                                <Link href={pagelink.guide.casino}><li data-bs-dismiss="offcanvas" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}>{ translate('Casino') }</li></Link>
                                <Link href={pagelink.guide.slots}><li data-bs-dismiss="offcanvas" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}>{ translate('Slots') }</li></Link>
                                <Link href={pagelink.guide.news}><li data-bs-dismiss="offcanvas" className="list-group-item px-0 border-0" style={{ fontSize: 13 }}>{ translate('News') }</li></Link>
                            </ul>
                        </div>
                    </li>
                    <Link href={pagelink.bonus.homepage}><li data-bs-dismiss="offcanvas" className="list-group-item px-0">{ translate('Bonus') }</li></Link>
                    <Link href={pagelink.general.termsAndCondition}><li data-bs-dismiss="offcanvas" className="list-group-item px-0">{ translate('Terms_conditions') }</li></Link>
                    <Link href={pagelink.general.privacyPolicy}><li data-bs-dismiss="offcanvas" className="list-group-item px-0">{ translate('Privacy_policy') }</li></Link>
                    <Link href={pagelink.general.responsibleGaming}><li data-bs-dismiss="offcanvas" className="list-group-item px-0">{ translate('Responsible_gaming') }</li></Link>
                    <Link href={pagelink.general.contactUs}><li data-bs-dismiss="offcanvas" className="list-group-item px-0">{ translate('Contact_us') }</li></Link>
                    <Link href={pagelink.general.monthlySponsored}><li data-bs-dismiss="offcanvas" className="list-group-item px-0">Partnership</li></Link>
                    {
                        hasMember && <li className="list-group-item px-0 border-0"><Button type='button' fullWidth className='btn shadow-none btn-filled gradient-hover-effect border-0 m-0 w-50' onClick={() => { deleteCookie('user'); router.reload() }}>Logout</Button></li>
                    }
                    <li className="list-group-item px-0 border-0" style={{ color: '#bb9b6a' }}>Follow Us Here</li>
                    <li className="list-group-item p-0 border-0" style={{ color: '#bb9b6a' }}>
                        <div className="row row-cols-7 g-0">
                            <div className="col mx-auto px-1"><a target={"_blank"} rel="nofollow" href="https://www.facebook.com/profile.php?id=100084059957881"><img src="/assets/images/social-media/svg/facebook.svg" alt="" className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
                            <div className="col mx-auto px-1"><a target={"_blank"} rel="nofollow" href="https://www.instagram.com/ugrado_official/"><img src="/assets/images/social-media/svg/instagram.svg" alt="" className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
                            <div className="col mx-auto px-1"><a target={"_blank"} rel="nofollow" href="https://www.pinterest.com/ugrado_official/"><img src="/assets/images/social-media/svg/pinterest.svg" alt="" className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
                            <div className="col mx-auto px-1"><a target={"_blank"} rel="nofollow" href="https://www.tiktok.com/@ugrado"><img src="/assets/images/social-media/svg/tiktok.svg" alt="" className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
                            <div className="col mx-auto px-1"><a target={"_blank"} rel="nofollow" href="https://twitter.com/ugrado_official"><img src="/assets/images/social-media/svg/twitter.svg" alt="" className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
                            <div className="col mx-auto px-1"><a target={"_blank"} rel="nofollow" href="https://www.youtube.com/channel/UCQkeDO7V8gWVY-15ARoxUfQ"><img src="/assets/images/social-media/svg/youtube.svg" alt="" className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
                            <div className="col mx-auto px-1"><a target={"_blank"} rel="nofollow" href="https://t.me/ugrado"><img src="/assets/images/social-media/svg/telegram.svg" alt="" className="img-fluid" style={{ maxHeight: 50 }} /></a></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        {/* Header */}
        {/* <header>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="navigation">
                            <nav className="navbar navbar-expand-xl">
                                <Link href={pagelink.home.homepage}>
                                    <a className="navbar-brand d-block d-xl-none"><img src="/assets/images/logo-mobile.png" alt="" className="img-fluid" /></a>
                                </Link>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <img src="/assets/images/menu-icon.png" alt="" className="img-fluid" />
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <Link href={pagelink.home.homepage}>
                                                <a className="nav-link active">{t('Home')}</a>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link href="/review">
                                                <a className="nav-link">{t('Review')}</a>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link href={pagelink.guide.homepage}>
                                                <a className="nav-link">{t('Guide')}</a>
                                            </Link>
                                            <div className="submenu">
                                                <ul>
                                                    <li><Link href={pagelink.guide.sports}>{t('Sports')}</Link></li>
                                                    <li><Link href={pagelink.guide.casino}>{t('Casino')}</Link></li>
                                                    <li><Link href={pagelink.guide.slots}>{t('Slots')}</Link></li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                    <Link href="/">
                                        <a className="navbar-brand d-none d-xl-block"><img src="/assets/images/logo.png" alt="" className="img-fluid" /></a>
                                    </Link>
                                    <ul className="navbar-nav navbar-nav-right">
                                        <li className="nav-item">
                                            <Link href="/about-us">
                                                <a className="nav-link">{t('About_us')}</a>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link href={pagelink.general.contactUs}>
                                                <a className="nav-link">{t('Contact_us')}</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="header-right ms-auto">
                                    <ul>
                                        {
                                            hasDiamond &&
                                            <li className="d-none d-xl-block">
                                                <span className="diamond-user"><img src="/assets/images/diamond-icon.png" alt="" />{diamond}</span>
                                            </li>
                                        }
                                        <li className="d-none d-xl-block m-0">
                                            {
                                                hasMember ? 
                                                <Link href={pagelink.user.profile}>
                                                    <a style={{cursor:'pointer'}}><img src="/assets/images/user-icon.png" alt="" className="img-fluid" /></a>
                                                </Link> 
                                                : <Link href={pagelink.authentication.login}>
                                                    <a style={{cursor:'pointer'}}><img src="/assets/images/user-icon.png" alt="" className="img-fluid" /></a>
                                                </Link>
                                            }
                                        </li>
                                        <li>
                                            <a className="search-icon" style={{ cursor: 'pointer' }} onClick={()=>{ openSearchDropdown() }}>
                                                <img src="/assets/images/search-icon.png" alt="" className="img-fluid" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header> */}

        {/*<nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="#">Header</a>
            <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                        <Link href="/auth/login"><a className="nav-link">Login</a></Link>
                    </li>
                    <li className="nav-item">
                        <Link href="/auth/signup"><a className="nav-link">Signup</a></Link>
                    </li>
                </ul>
            </div>
        </nav>*/}
    </>
}
