import { LoadingOverlay } from '@mantine/core';
import { useEffect, useLayoutEffect, useState } from "react";
import { _getURL } from "@/api/index";
import Redux, { updatePath } from "@/action";
import Footer from "./footer";
// import Navbar from "./navbar";
import Header from "./header";

import $ from 'jquery';

export default function Layout(props) {
    const { children } = props;
    const { t } = children.props;
    const { store, dispatch } = Redux();
    const [displaysearchdropdown, setdisplaysearchdropdown] = useState(false);

    // Open search menu
    const _openSearchDropdown = () => {
        setdisplaysearchdropdown(true);
    }

    // Close search menu
    const _closeSearchDropdown = () => {
        setdisplaysearchdropdown(false);
    }

    useEffect(() => {
        const headerHeight = document.querySelector('header').offsetHeight;

        $('.main').css('padding-top', headerHeight);
    });

    return <>
        <div className="wrapper">
            {/* <Header {...children.props} openSearchDropdown={_openSearchDropdown}/> */}
            <Header {...children.props}/>
            {/* <Navbar {...children.props} /> */ }

            {/* <!-- main content start here --> */}
            <div className="main">
                {children}
            </div>
            {   /* <!-- main content ends here --> */}

            <Footer {...children.props}/>
        </div>
        <div className={ displaysearchdropdown ? "search-dropdown active" : 'search-dropdown'}>
            <form>
                <span><img src="/assets/images/search-icon.png" alt="" /></span>
                <input type="text" className="form-control" placeholder="Search" />
                <a className="close-btn" style={{ cursor: 'pointer' }} onClick={()=>{ _closeSearchDropdown() }}><img src="/assets/images/cross2.png" alt="" /></a>
            </form>
            <div className="text-box">
                <h4>Recent Searches</h4>
                <ul>
                    <li><a href="#"><img src="/assets/images/clock-icon2.png" alt="" /> Mega888</a></li>
                    <li><a href="#"><img src="/assets/images/clock-icon2.png" alt="" />Free credit slots</a></li>
                </ul>
            </div>
            <div className="text-box mb-0">
                <h4>Popular Tags</h4>
                <ul>
                    <li><a href="#">5 star Online Casinos</a></li>
                    <li><a href="#">Best Welcome Bonus</a></li>
                    <li><a href="#">2022 Best Online Casino</a></li>
                </ul>
            </div>
        </div>
    </>
}