import { createStore } from 'redux'
import { persistStore, createTransform, persistReducer } from 'redux-persist'
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// import CryptoJS from 'crypto-js';
import reducer from './reducers'
import { encryptTransform } from 'redux-persist-transform-encrypt';

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const persistConfig = {
  key: '__next',
  storage: typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage(),
  // storage: typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage(),
  // stateReconciler: autoMergeLevel2, // 查看 'Merge Process' 部分的具体情况
  // transforms: [encrypt],
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_PUBLIC_STORE_SECRET_KEY,
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],

}

const myPersistReducer = persistReducer(persistConfig, reducer)

const store = createStore(myPersistReducer)

export const persistor = persistStore(store)
export default store
