import * as types from './types'
import { useSelector, useDispatch } from "react-redux";

export const initialState = () => ({ type: types.RESET })
export const changeLanguage = ({ languge = "en" }) => ({ type: types.CHANGE_LANGUAGE, languge })

export const updateUser = ({ user = {} }) => ({ type: types.UPDATE_USER, user });

export const resetPath = () => ({ type: types.RESET_PATH });

export const updatePath = ({ key, path }) => ({ type: types.UPDATE_PATH, key, path });

export const resetUser = () => ({ type: types.RESET_USER });
export const toggleLoading = (status) => ({ type: types.TOGGLE_LOADING, status });

const Action = () => {
    const store = useSelector((state) => state)
    const dispatch = useDispatch()
    return { store, dispatch };
}

export default Action